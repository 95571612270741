import React from 'react'
import { makeStyles } from '@mui/styles';
import woodenFrame6 from '../../../../../Assets/LandingPage/woodenFrame6.png';
import emptyTreasure from '../../../../../Assets/LandingPage/Treasure_Chest_Empty.png';
import pirateFlag from '../../../../../Assets/LandingPage/pirate-flag.gif';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
const useStyles = makeStyles((theme:any)=>({
    container:{
        position:"relative",
        height:"210px",
        width:'373px',
        '&::before':{
            content:"''",
            backgroundImage:`url(${woodenFrame6})`,
            backgroundPosition:"center",
            backgroundSize:"100%",
            backgroundRepeat:"no-repeat",
            position:"absolute",
            width:'100%',
            height:'100%',
            left:0,
            zIndex:-1,
            [theme.breakpoints.down('md')]:{
            backgroundSize:"80%",

            }
        }
    },
    flexRow:{
        // paddingTop:'40px',
            display:"flex",
            justifyContent:"center",
            alignItems:'center',
            fontSize:"1.875rem",
            gap:"10px",
            height:"100%",
            [theme.breakpoints.down('sm')]:{
                fontSize:"1.25rem",

            }
        
           
           
    },
    flag:{
        position:"absolute",
        bottom:'45px',
        right:"-170px",
        width:"100%",
        display:"flex",
        justifyContent:"flex-end",
        zIndex:-1,
        [theme.breakpoints.down('md')]:{
            bottom:'55px',
            right:"10px" 
        }
    },
    mintPirates:{
        position:"relative",
        top:'-45px',
        [theme.breakpoints.down('md')]:{
            top:'-65px',

        },
        '& button':{
            background:"#bb2719",
            color:"white",
            fontSize:'0.75rem',
            padding:"10px 15px",
            borderRadius:"25px",
            cursor:"pointer"
         
        },
        '& button:hover':{
            background:"#660c04"
        }
    }
}))
const SoldOut = () => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <div className={classes.container}>
                <div className={classes.flexRow}>
              <img src={emptyTreasure} alt="" width={matches ? "80px" :"120px"}/>
            <p>SOLD <br/> OUT</p>
                
            </div>
            <div className={classes.flag}>
                <img src={pirateFlag} alt=""  width={matches ? "50px" :"200px"}/>
            </div>
            <div className={classes.mintPirates}>
                <button>BUY ON OPENSEA</button>
            </div>
        </div>
    )
}

export default SoldOut

import React, { useEffect, useState } from 'react';
import './App.css';

import LandingPage from './Pages/LandingPage/LandingPage';
import {BrowserRouter as Router,Switch,Route} from 'react-router-dom';
import PersonalPage from './Pages/PersonalPage/PersonalPage';
import {
  ModalProvider
} from 'react-simple-hook-modal';
import {  createTheme,ThemeProvider } from '@mui/material/styles';
import Navbar from './Pages/LandingPage/Components/Navbar/Navbar';
import PageContext from './Context/pageContext';
// import { ParallaxProvider } from 'react-scroll-parallax';


const theme = createTheme({});

function App() {
  const [location,setLocation]=useState(false);


  const locationChecker=()=>{
   
    if(window.location.pathname.includes('personal-page')){
      setLocation(true);
      return true
    }else{
      setLocation(false);
      return false
    }
    
  }
  useEffect(() => {
    locationChecker()
  }, [])
  return (

    <ThemeProvider theme={theme}>
      {/* <ParallaxProvider> */}
        <ModalProvider>
          <PageContext.Provider value={{setLocation:setLocation}}>
            <Router>
              <Navbar personal={location}/>
                <Switch>
                  <Route exact path="/" component={LandingPage} />
                  {/* <Route  path="/personal-page" component={PersonalPage} /> */}
                </Switch>
            </Router>
          </PageContext.Provider>
        </ModalProvider>
      {/* </ParallaxProvider> */}
    </ThemeProvider >
    
  
  );
}

export default App;

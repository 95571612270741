import React from 'react'
import { makeStyles } from '@mui/styles';
import whatBg from '../../../../Assets/LandingPage/whatBg.svg';
import aboutBottom from '../../../../Assets/LandingPage/what_path.png';
import what_ground1 from '../../../../Assets/LandingPage/pirate_sand02.png';
import what_ground2 from '../../../../Assets/LandingPage/what_ground2.png';
import Person from '../../../../Assets/LandingPage/person.png';
import Pirate from '../../../../Assets/LandingPage/pirate.png';
import GhostPirate from '../../../../Assets/LandingPage/ghost-pirate.png';
import FemalePirate from '../../../../Assets/LandingPage/female-pirate.png';
import SkeletonPirate from '../../../../Assets/LandingPage/skeleton-pirate.png';
import ZombiePirate from '../../../../Assets/LandingPage/zombie-pirate.png';
import UnknownPirate from '../../../../Assets/LandingPage/unknown-pirate.png';
import ship from '../../../../Assets/LandingPage/ship.png';
import borderFrame from '../../../../Assets/LandingPage/what_frame.png';
import treasureChestOpen03 from '../../../../Assets/LandingPage/treasure_chest_open_03.svg';
import { useTheme } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { fontSize } from '@mui/system';

const useStyles=makeStyles((theme:any)=>({
    whatRoot:{
        position: 'relative',
        marginTop: '2em',
        '& p':{
            fontFamily:"'PT Sans', sans-serif",
            fontSize: '1rem',
            fontWeight: '600'
        },
        '&::before':{
            content:"''",
            backgroundImage:`url(${whatBg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            position: 'absolute',
            width: '100%',
            height: '100%',
            // [theme.breakpoints.down('md')]:{
            //     height:'100%'
            // },
            zIndex:-1,
            top: '-60px',
         }
    },
    selectorContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexFlow: 'wrap',
        marginTop: '3em',
        "& $highlightBox": {
            color: '#6e2424',
            display: 'flex',
            alignItems: 'center',
            margin: '0 1em 2em',
            padding: '0.4em 2em',
            cursor: 'pointer',
            maxHeight: '4.3em',
            filter: 'grayscale(0.6)',
            [theme.breakpoints.down(615)]:{
                fontSize: '0.6em',
                maxHeight: 'inherit'
            },
        },
        "& $tabSelected": {
            color: 'inherit',
            paddingTop: '1em',
            paddingBottom: '1em',
            maxHeight: 'inherit',
            filter: 'none'
        }
    },
    tabSelected: {},
    description:{
        position:"relative",
        minHeight: '46em',
        zIndex: 4,
        color: 'rgb(127 70 17)',
        width: '100%',
        maxWidth:"680px",
        margin:"0 auto",
        textAlign: 'justify',
        [theme.breakpoints.down(1180)]:{
            width:"70%"
        },
        '& h2':{
            fontSize:"2rem",
            fontWeight:"500",
            color: "#000000",
            marginBottom: '0.5em',
            padding:"0 1em",
            textTransform:"uppercase",
            textAlign: 'center',
            letterSpacing:"1.3px",
            lineHeight: '1.3em',
            '& span': {
                color: '#bc2819'
            }
        },
        '& strong': {
            display: 'inline-block',
            fontFamily: '\'PT Sans\', sans-serif',
            fontSize: '1.3em'
        },
        '& > *': {
            padding: '0 1em',
            margin: 0
        },
        [theme.breakpoints.down('md')]: {
            width: '80%',
            marginBottom: '11em'
        }
    },
    descriptionTop:{
        letterSpacing: '0.9px',
        marginBottom: '4em',
        paddingLeft: '1em',
        lineHeight: '1.4rem',
        '& p': {
            margin: '0.5em 0'
        },
        '& a': {
            color: '#bc281a',
            fontWeight: 'bold'
        }
    },
    descriptionBottom:{
        letterSpacing:"0.9px",
        lineHeight:"1.4rem",
        marginTop: '1em',
        'p&': {
            padding: '0 1em'
        },
        [theme.breakpoints.down('md')]:{
            padding:'10px',
        }
    },
    descriptionLineWithImage: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    highlightBox:{
        position:"relative",
        zIndex: 1,
        paddingBottom: '0.8em',
        fontSize:"0.875rem",
        '&::before':{
            content:"''",
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
            backgroundPosition: 'center',
            position:"absolute",
            width:"115%",
            height:"100%",
            left:"-40px",
            right:"-50px",
            top:"0px",
            bottom:0,
            zIndex:-1
        },
        '& p': {
            marginBottom: 0,
            '& b': {
                color: '#000000',
                fontSize: '1.1em'
            }
        },
        '& strong': {
            marginTop: '1em'
        }
    },
    aboutBottomContainer:{
        position:"relative",
        minHeight:'227px',
        top:"0px",
        '&::before':{
            content:"''",
            backgroundImage:`url(${aboutBottom})`,
            backgroundColor:`#CEECFA`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
            backgroundPosition: 'center',
            position:"absolute",
            width:"100%",
            height:"100%",
            top:"-4em",
            zIndex:-1,
            [theme.breakpoints.down('md')]:{
                top:"-5em"
            }
        },
        '&::after':{
            content:"''",
            backgroundImage:`url(${what_ground2})`,
            backgroundRepeat: 'repeat-x',
            backgroundSize: '770px',
            backgroundPosition: '99% 50%',
            position:"absolute",
            width:"100%",
            height:"100%",
            top:"0",
            zIndex:2,
            [theme.breakpoints.down('md')]:{
                top:"-24px",

            }
        }

    },
    verticalSelectorContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '1em',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    verticalSelector: {
        display: 'flex',
        flexDirection: 'column',
        border: '7px #bc281a dashed',
        borderRadius: '1em',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center'
        }
    },
    verticalSelectorItem: {
        fontSize: '0.7em',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '1em',
        textAlign: 'center',
        cursor: 'pointer',
        filter: 'grayscale(1)',
        opacity: 0.3,
        "&$verticalSelectorItemSelected": {
            filter: 'none',
            opacity: 1
        }
    },
    verticalSelectorItemSelected: {},
    verticalSelectorContent: {
        marginLeft: '1em',
        borderRadius: '1em',
        border: '7px #bc281a dashed',
        padding: '0.2em 1em',
        [theme.breakpoints.down('sm')]: {
            margin: '1em 0 0'
        },
        '& h2': {
            margin: '0.5em 0',
            fontSize: '1.5em'
        },
        '& p': {
            margin: '0.8em 0'
        },
        '& strong': {
            fontSize: '1.2em',
            marginBottom: '0.2em'
        }
    },
    pictureContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    pirateImage:{
        position:"absolute",
        left: 0,
        zIndex: 3,
        width: '15em',
        bottom:'2.8em',
        [theme.breakpoints.down('md')]: {
            bottom: '5em'
        },
        [theme.breakpoints.down('sm')]: {
            width: '10em',
            bottom: '5em'
        }
    },
    ship:{
        position:"absolute",
        width: '20em',
        right: 0,
        bottom:"10em",
        [theme.breakpoints.down(1280)]: {
            width: '15em',
        },
        [theme.breakpoints.down('md')]: {
            width: '15em',
            bottom: '11em',
            right: 0
        },
    },
    borderimg2:{
        borderStyle: 'solid',
        borderWidth: '10px',
        borderImage: `url(${borderFrame}) 45 stretch`,
        borderImageWidth: '20px',
        background:`#ffd554`,
        backgroundClip:"padding-box"
    },
}))
const What = () => {
    const classes = useStyles();
    const theme = useTheme();
    const [activeTab,setActiveTab] = React.useState(0);
    const [activeVerticalSelector,setActiveVerticalSelector] = React.useState(0);
    // const matches = useMediaQuery(theme.breakpoints.down('md'));
    // const matchesTablet = useMediaQuery(theme.breakpoints.up('md'));
    // const matchesTabletLg = useMediaQuery(theme.breakpoints.down('lg'));
    const tabClassNames = `${classes.highlightBox} ${classes.borderimg2}`;

    return (
        <div className={`${classes.whatRoot}`}>
            <div className={classes.selectorContainer}>
                <div
                    className={`${tabClassNames}${activeTab == 0 ? ` ${classes.tabSelected}` : ''}`}
                    onClick={() => setActiveTab(0)}
                >
                    ABOUT
                </div>
                <div
                    className={`${tabClassNames}${activeTab == 1 ? ` ${classes.tabSelected}` : ''}`}
                    onClick={() => setActiveTab(1)}
                >
                    THE VIDEOGAME
                </div>
                <div
                    className={`${tabClassNames}${activeTab == 2 ? ` ${classes.tabSelected}` : ''}`}
                    onClick={() => setActiveTab(2)}
                >
                    THE TREASURE HUNT
                </div>
                <div
                    className={`${tabClassNames}${activeTab == 3 ? ` ${classes.tabSelected}` : ''}`}
                    onClick={() => setActiveTab(3)}
                >
                    THE COLLECTIBLES
                </div>
            </div>
            { activeTab == 0 ?
                <div className={classes.description}>
                    <h2>
                        What <span>are</span> crypto <span>pirates</span>?
                    </h2>
                    <div className={classes.descriptionTop}>
                        <p>
                            Crypto Pirates are an NFT Collectibles and a Strategy Videogame in which each NFT owner will fight, pirate against pirate during an event called "The Grand Treasure Hunt".
                        </p>
                        <p>
                            During each Treasure Hunt, only the quickest group of pirates will be able to open one of the many treasure chests with the keys that they have in their possession.
                        </p>
                        <p>
                            Each treasure chest has a loot, but the luckiest group of pirates will be able to put their hands on the Grand Treasure Chest, which contains the richest Loot of the Seven Seas!
                        </p>
                        <div
                            className={`${classes.highlightBox} ${classes.borderimg2} mt-8 `}
                            style={{padding: "0.1em 1em 1em 1em"}}
                        >
                            <p>
                                Once Crypto Pirates NFTs are sold out, the first Grand Treasure Hunt will begin.
                            </p>
                            <p>
                                During the First Treasure Hunt we will distribute prizes for <b>15 ETH</b> with a GRAND PRIZE of <b>9 ETH</b>!
                            </p>
                            <strong>
                                50% of our NFT owners wins a prize on every Grand Treasure Hunt! Guaranteed!
                            </strong>
                        </div>
                    </div>
                </div>
                : activeTab == 1 ?
                    <div className={classes.description}>
                        <h2>
                            The <span>VIDEOGAME</span>
                        </h2>
                        <div className={classes.descriptionTop}>
                            <p>
                                Crypto Pirates will be a strategy videogame that will immerse the players in a pirate metaverse.
                                The Crypto Pirate world is a place where the player can grow their business like taverns, armories, shops, etc.
                            </p>
                            <span className={classes.descriptionLineWithImage}>
                                <img src={Person} />
                                <p>
                                    A non-pirate player doesn't need to have a pirate NFT to play, but must to fulfill one of these requirements: they need to work for someone else who can give them a place to sleep or own an NFT land or a ship.
                                    As the player grows and earns money, he will be able to buy ships of different sizes and go to other lands to trade and collect precious items for his business.
                                </p>
                            </span>
                            <p>
                                But the waters are not always a safe place because the player might encounter the pirates.
                                To defend their precious cargo, they have to fight, and during the fight they might lose part of the cargo.
                            </p>
                            <span className={classes.descriptionLineWithImage}>
                            <img src={Pirate} />
                                <span>
                                    <p>
                                        On the other side, the pirates have their ecosystem with their hideouts on several islands,
                                        a pirate council whose rules they must abide to, and adventures that will earn them rewards.
                                    </p>
                                </span>
                            </span>
                            <span className={classes.descriptionLineWithImage}>
                                <img src={GhostPirate} />
                                <p>
                                    The archenemies of the pirates are the ghost pirates who, with their ghost ships, are always ready to steal the loots from the pirates when they come back from one of their adventures.
                                </p>
                            </span>
                        </div>
                    </div>
                    : activeTab == 2 ?
                        <div className={classes.description}>
                            <h2>
                                The <span>Treasure</span> Hunt
                            </h2>
                            <div className={classes.pictureContainer}>
                                <img src={treasureChestOpen03} style={{width: '10em'}} alt=""/>
                            </div>
                            <div className={classes.descriptionTop}>
                                <p>
                                    Once the Grand Treasure Hunt starts, each NFT owner has to log in to our website and unlock each key present in their NFTs.
                                </p>
                                <p>
                                    There are six keys capable of unlocking a treasure chest containing the loot, and each key is placed in a different NFT.
                                </p>
                                <p>
                                    The first three keys that unlock the treasure chest will win the prize, equally distributed among the NFT owners of the keys used to open the chest.
                                </p>
                                <p>
                                    If one NFT owner unlocks a treasure chest, the other two NFT owners will have a limited (but generous) amount of time to unlock their share.
                                    When the time elapses, the prize will be redistributed only to the people that have unlocked the treasure chest up to that moment.
                                </p>
                                <p>
                                    In theory, one pirate can grab the prize contained in the treasure chest if the other pirates haven't claimed their part on time.
                                    Once all the prizes are claimed, the Grand Treasure Hunt will end.
                                </p>
                                <p>
                                    We have lined up several Treasures Hunts after the first one that will happen when we sell out.
                                    If you don't win a prize during the first one, you might win the next one!
                                    Check our <a href="https://discord.gg/s3W2TVckkG" style={{ color: 'black', textDecoration: 'none'}} target="_blank">Discord server</a> FAQ to read more on how Crypto Pirates work.
                                </p>
                            </div>
                        </div>
                        : activeTab == 3 ?
                        <div className={classes.description}>
                            <h2>
                                The <span>Collectibles</span>
                            </h2>
                            <div className={classes.descriptionTop}>
                                <p>
                                Crypto Pirates Collectibles will be a series of NFTs that we will release at different times.
                                Each type of pirate has different characteristics in the game. 
                                The player will start the game with a different set of objects (like a different type of ship, amount of money for object customization, etc.)
                                based on the rarity of the pirate they own.
                                </p>
                                <p>
                                    Below are the different pirates types and their release dates:
                                </p>
                                <div className={classes.verticalSelectorContainer}>
                                    <div className={classes.verticalSelector}>
                                        <div 
                                            className={`${classes.verticalSelectorItem}${activeVerticalSelector === 0 ? ` ${classes.verticalSelectorItemSelected}` : ''}`}
                                            onClick={() => setActiveVerticalSelector(0)}
                                        >
                                            <img src={FemalePirate} />
                                            <span>THE PIRATE</span>
                                        </div>
                                        <div 
                                            className={`${classes.verticalSelectorItem}${activeVerticalSelector === 1 ? ` ${classes.verticalSelectorItemSelected}` : ''}`}
                                            onClick={() => setActiveVerticalSelector(1)}
                                        >
                                            <img src={SkeletonPirate} />
                                            <span>THE SKELETON</span>
                                        </div>
                                        <div 
                                            className={`${classes.verticalSelectorItem}${activeVerticalSelector === 2 ? ` ${classes.verticalSelectorItemSelected}` : ''}`}
                                            onClick={() => setActiveVerticalSelector(2)}
                                        >
                                            <img src={ZombiePirate} />
                                            <span>THE ZOMBIE</span>
                                        </div>
                                        <div 
                                            className={`${classes.verticalSelectorItem}${activeVerticalSelector === 3 ? ` ${classes.verticalSelectorItemSelected}` : ''}`}
                                            onClick={() => setActiveVerticalSelector(3)}
                                        >
                                            <img src={GhostPirate} />
                                            <span>THE GHOST</span>
                                        </div>
                                        <div 
                                            className={`${classes.verticalSelectorItem}${activeVerticalSelector === 4 ? ` ${classes.verticalSelectorItemSelected}` : ''}`}
                                            onClick={() => setActiveVerticalSelector(4)}
                                        >
                                            <img src={UnknownPirate} />
                                            <span>THE MYTHOLOGICAL CREATURE</span>
                                        </div>
                                    </div>
                                    { activeVerticalSelector === 0 ?
                                        <div className={classes.verticalSelectorContent}>
                                            <h2>
                                                THE <span>CRYPTO</span> PIRATES
                                            </h2>
                                            <p>
                                                This NFT series will be the first series that we will release. It has five kinds of rarities: Common, Uncommon, Rare, Ultra-rare, and Legendary.
                                            </p>
                                            <p>
                                                We will release the first four types during the presale and the official sale, and you will have a higher possibility of getting the Rare ones during the presale.
                                            </p>
                                            <p>
                                                The legendary pirates cannot be minted but only won during our contests, and their appearance is customizable by the user.
                                            </p>
                                            <br/>
                                            <span>
                                                MINTING CHANCES:
                                            </span>
                                            <p>ON PRESALE</p>
                                            <p>
                                                <strong>0%</strong> to get a Common Pirate
                                                <br/>
                                                <strong>20%</strong> to get an Uncommon Pirate
                                                <br />
                                                <strong>70%</strong> to get a Rare Pirate
                                                <br />
                                                <strong>10%</strong> to get an Ultra Rare Pirate.
                                            </p>
                                            <p>ON REGULAR SALE</p>
                                            <p>Same chance to get a Common, Uncommon, Rare, and Ultra Rare Pirate</p>
                                        </div>
                                        : activeVerticalSelector === 1 ?
                                            <div className={classes.verticalSelectorContent}>
                                                <h2>
                                                    THE <span>SKELETON</span> PIRATES
                                                </h2>
                                                <p>
                                                They belong to the Crypto Pirates NFT series and are classified as Uncommon, Rare, Ultra-rare, and Legendary.
                                                </p>
                                                <p>
                                                They have different skills in the game compared to the other Crypto Pirates.
                                                </p>
                                                <br/>
                                                <span>
                                                    MINTING CHANCES:
                                                </span>
                                                <br />
                                                <p>ON PRESALE</p>
                                                <p>
                                                    <strong>(33% TOTAL CHANCE)</strong> 11% to get an Uncommon, Rare, and Ultra Rare Skeleton Pirate
                                                </p>
                                                <br/>
                                                <p>ON REGULAR SALE</p>
                                                <p>
                                                    <strong>(25% TOTAL CHANCE)</strong> 8% to get an Uncommon, Rare, and Ultra Rare Skeleton Pirate
                                                </p>
                                            </div>
                                        : activeVerticalSelector === 2 ?
                                            <div className={classes.verticalSelectorContent}>
                                                <h2>
                                                    THE <span>ZOMBIE</span> PIRATES
                                                </h2>
                                                <p>
                                                They belong to the Crypto Pirates NFT series and are classified as Uncommon, Rare, Ultra-rare, and Legendary.
                                                </p>
                                                <p>
                                                They have different skills in the game compared to the other Crypto Pirates.
                                                </p>
                                                <br/>
                                                <span>
                                                    MINTING CHANCES:
                                                </span>
                                                <p>ON PRESALE</p>
                                                <p>
                                                    <strong>(33% TOTAL CHANCE)</strong> 11% to get an Uncommon, Rare, and Ultra Rare Skeleton Zombie
                                                </p>
                                                <br />
                                                <p>ON REGULAR SALE</p>
                                                <p>
                                                    <strong>(25% TOTAL CHANCE)</strong> 8% to get an Uncommon, Rare, and Ultra Rare Skeleton Zombie
                                                </p>
                                            </div>
                                        : activeVerticalSelector === 3 ?
                                        <div className={classes.verticalSelectorContent}>
                                            <h2>
                                                THE <span>GHOST</span> PIRATES
                                            </h2>
                                            <p>
                                                This NFT series cannot be minted but only obtained if you trade in one or more of your failed/rugged NFTs that you have in your wallet.
                                            </p>
                                            <p>
                                                The Ghosts cannot participate in our Grand Treasure Hunt because they don't own a treasure chest key, but they will have a random chance to steal part of it from other pirates.
                                            </p>
                                            <p>
                                                They have pretty unique skills in the game, from the simple fog disguise skill to the most advanced teleportation skill.
                                            </p>
                                        </div>
                                        : activeVerticalSelector === 4 ?
                                        <div className={classes.verticalSelectorContent}>
                                            <h2>
                                                THE <span>MYTHOLOGICAL</span> CREATURES
                                            </h2>
                                            <p>
                                                This NFT series cannot be minted and can only be found as loot in a treasure chest.
                                            </p>
                                            <p>
                                                These creatures will allow the Crypto Pirate owners to gain unique in-game skills and participate in a more exclusive Treasure hunt: The Poseidon Challenge with ultra-exclusive prizes with a higher value.
                                            </p>
                                        </div>
                                        : ''
                                    }
                                </div>
                            </div>
                        </div>
                        : ''
            }
            <img className={classes.ship} src={ship} alt=""/>
            <div className={classes.aboutBottomContainer}>
                <img className={classes.pirateImage} src={what_ground1} alt="" />
            </div>
        </div>
    )
}

export default What

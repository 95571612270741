import React, {useState } from 'react'
import { makeStyles } from '@mui/styles';
import minting_box from '../../../../../Assets/LandingPage/minting_box.png';
import treasure_chest_closed from '../../../../../Assets/LandingPage/treasure_chest_closed.png';
import cross from '../../../../../Assets/LandingPage/cross.png'
import pirateFlag from '../../../../../Assets/LandingPage/pirate-flag.gif';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
const useStyles = makeStyles((theme:any)=>({
    wrapper:{
        position:"relative"
    },
    container:{
        position:"relative",
        height:"110px",
        width:'323px',
        '&::before':{
            content:"''",
            backgroundImage:`url(${minting_box})`,
            backgroundPosition:"center",
            backgroundSize:"100%",
            backgroundRepeat:"no-repeat",
            position:"absolute",
            width:'100%',
            height:'100%',
            left:0,
            zIndex:-1
        }
    },
    treasureContainer:{
        marginTop:"30px",
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        gap:"30px",
        position:"relative",
        zIndex:9999,
    },
    countSpan:{
        color:"#bb2719",
        fontSize:"1.75rem"
    },
    flexRow:{
        paddingTop:'20px',
            display:"flex",
            justifyContent:"center",
            alignItems:'center',
            fontSize:"0.625rem",
            gap:"10px",
            '& p':{
                lineHeight:"14px",
                marginBottom:"4px"
            },
            '& input':{
                background:'rgb(41, 41, 41)',
                color:'white',
                padding:"2px 15px",
                borderRadius:"6px",
                width:"50px"
            }
           
           
    },
    plus:{
        position:'relative',
        width:"25px",
        height:"25px",
        color:"white",
        fontSize:"0.75rem",
        // fontWeight:"bold",
        cursor:'pointer',
        background:"none",
        border:'none',
        fontWeight:"bold",

        
        '&::before':{
            content:'""',
            borderStyle: 'solid',
            borderWidth: '13px 18px 13px 0',
            borderColor: 'transparent #000 transparent transparent',
            position: 'absolute',
            left: '3px',
            top:"0px",
            transform:"rotate(-270deg)",
            zIndex:-1
        }
    },
    minus:{
        position:'relative',
        width:"25px",
        height:"25px",
        color:"white",
        right:"10px",
        fontSize:"0.75rem",
        cursor:'pointer',
        background:"none",
        border:'none',
        fontWeight:"bold",


        // fontWeight:"bold",
        
        '&::before':{
            content:'""',
            borderStyle: 'solid',
            borderWidth: '13px 18px 13px 0',
            borderColor: 'transparent #000 transparent transparent',
            position: 'absolute',
            left: '3px',
            top:"0px",
            transform:"rotate(-90deg)",
            zIndex:-1
        }
    },
    minusDisabled:{
        '&::before':{
           
            borderColor: 'transparent grey transparent transparent !important',
        
        }

    },
    spanText:{
        color:"#bb2719",
        fontSize:"0.5rem",
        marginLeft:'4px'
    },
    flag:{
        position:"absolute",
        bottom:'27px',
        right:"-75px",
        width:"100%",
        display:"flex",
        justifyContent:"flex-end",
        zIndex:-1,
        [theme.breakpoints.down('md')]:{
            bottom:'27px',
            right:"-25px",
        }
    },
    mintPirates:{
        position:"relative",
        '& button':{
            background:"#bb2719",
            color:"white",
            fontSize:'0.75rem',
            padding:"10px 15px",
            borderRadius:"25px",
            cursor:"pointer"
         
        },
        '& button:hover':{
            background:"#660c04"
        }
    }
}))
const Minting = ({setSoldOut}:any) => {
    const classes =useStyles();
 
    const [counter,setCounter] = useState<any>(10);
    const [error,setError] = useState(false);
    const [disable,setDisabled] = useState(false);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    const counterHandler =(type:string)=>{
     
        let decrement:any = counter;
        let increment:any = counter;
        if(type === 'increment'){
            if(counter < 20){
                increment++;
                disableFunction(increment);

                setCounter(increment);
                setError(false);

            }
        }else if(type === 'decrement'){
       

            if(counter > 0){
         
            decrement--;
            disableFunction(decrement)
            setCounter( decrement);
            setError(false)
            }
        }else{
            setError(true)
        }
       
      
    }
    const oninputChangeHandler=(e:any)=>{
        let value =Number(e.target.value);
       console.log(value);
       
        if(value < 0)return
        // if(value === 20) setDisabled(true)
        disableFunction(value);
        if((value || counter) > 20){
            setError(true)
        }else{
            setError(false)
        }
        setCounter(value)
    }
    const disableFunction=(value:Number)=>{
        if(value === 0) {
            setCounter(0)
            setDisabled(true)
        }else{
            setDisabled(false)
        }
    }
    return (<div className={classes.wrapper}>
        <div className={classes.treasureContainer}>
            <img src={treasure_chest_closed} alt="" width="100px"/>
            <div className="d-flex flex-column">
                <span className={classes.countSpan}>10,000</span>
                <span>TOKENS SOLD</span>
            </div>
        </div>
        <div className={classes.container}>
            <div className={classes.flexRow}>
                <p className="text-center">CHOOSE HOW MANY PIRATES <br/> YOU WANT TO MINT</p>
                <input type="number" value={String(counter)} onChange={oninputChangeHandler}/>
                
                <button  onClick={()=>counterHandler('increment')} className={`${classes.plus} ${(error || counter ===20) && classes.minusDisabled} noselect`} >+</button>
                <button   onClick={()=>counterHandler('decrement')} className={`${classes.minus} ${(disable) && classes.minusDisabled} noselect`}>-</button>
                
            </div>
           {error && (
            <div className="d-flex align-items-center justify-content-center">
                <img src={cross} alt=""  width="14px"/>
                <span className={classes.spanText}>NO MORE THAN 20 PIRATES ARE ALLOWED TO BE MINTED</span>
            </div>
           )} 
            <div className={classes.flag}>
                <img src={pirateFlag} alt="" width={matches ? "50px" :"100px"}/>
            </div>
            <div className={classes.mintPirates}>
                <button onClick={()=>setSoldOut(true)}>MINT PIRATES</button>
            </div>
        </div>
        </div>
    )
}

export default Minting

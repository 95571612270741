import React from 'react'

import { makeStyles } from '@mui/styles';
import water from '../../../../Assets/LandingPage/Water.png';
import borderFrame from '../../../../Assets/PersonalPage/borderFrame.png';
import GhostPirate from '../../../../Assets/LandingPage/ghost-pirate.png';
import treasureChestOpen02 from '../../../../Assets/LandingPage/treasure_chest_open_02.svg';
import treasureChestOpen03 from '../../../../Assets/LandingPage/treasure_chest_open_03.svg';
import pirateFlag from '../../../../Assets/LandingPage/pirate-flag.gif';
import arcade from '../../../../Assets/LandingPage/arcade.gif';
import land from '../../../../Assets/LandingPage/land.gif';
import { ReactComponent as Discord } from '../../../../Assets/LandingPage/discord.svg';

const useStyles = makeStyles((theme:any)=>({
    roadmapRoot:{
        position:"relative",
        minHeight:"80vh",
        zIndex: 1,
        '&::before':{
            content:"''",
            backgroundImage:`url(${water})`,
            backgroundSize:"cover",
            backgroundPosition:"center",
            backgroundRepeat:"no-repeat",
            position:"absolute",
            left:0,
            right:0,
            top:"-8.5em",
            width:"100%",
            height:"180%",
            zIndex:-1,
            // [theme.breakpoints.down(1054)]: {
            //     top: '-8.5em',
            // },
            [theme.breakpoints.down('md')]: {
                top: '-5em'
            }
        },
    },
    container:{
        position:"relative",
        top:"0px",
        height:"100%",
        marginBottom:"50px",
        maxWidth:"var(--max-width)",
        padding:"0 50px",
        boxSizing:"border-box",
        margin:"0 auto",
        [theme.breakpoints.down('md')]:{
            padding:"0 0px",

        },
      
    },
    containerBody:{
        paddingTop:"20px",
        width:'100%',
        margin: '50px auto',
        paddingBottom:'35px',
        '& h2':{
            fontSize:"1.725rem",
            letterSpacing:"1.3px",
            marginBottom:0,
            '& > span': {
                color: '#bc2819'
            }
        },
        [theme.breakpoints.down('md')]:{
            boxSizing:"content-box",
        width:'80%',

        },
        [theme.breakpoints.up('xl')]:{
            width:'100%',

        },
    },
    textGrid:{
        display:"flex",
        justifyContent:"flex-start",
        alignItems:"center",
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns:"1fr",
            display:"flex",
            flexDirection:"row",
                justifyContent:"flex-start",
                alignItems:"flex-start"
        },
        gap:"77px",
        width:'100%',
        margin:'0 auto',
        '& $itemContainer':{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
            '& p':{
                width:"221px",
                fontSize:"16px",
                paddingBottom:"5px",
                [theme.breakpoints.down('md')]:{
                    width:"226px"
                }
            },
            [theme.breakpoints.down('md')]:{
                flex:'1 1 100%',
                width:"100%"
            },
            '& $title':{
                textAlign:"center",
                fontSize:"1.5rem",
                margin: 0
            },
            '& $subtitle': {
                textAlign:"center",
                fontSize: '1rem',
                margin: '0 0 1em 0'
            },
            '& $description':{
                textAlign:'justify',
                margin: '0 1em',
                justifySelf:"end",
                fontSize: '1.2em'
            }
        }
    },
    pictureContainer: {
        display: 'flex',
        alignItems: 'end',
        height: '12em',
        textAlign: 'center',
        '& $treasureChestOpen02': {
            width: '18em'
        },
        '& $itemImage': {
            width: '11em',
            marginBottom: '1em'
        },
        '& $arcade': {
            width: '6em'
        }
    },
    itemContainer: {},
    itemImage: {},
    treasureChestOpen02: {},
    arcade: {},
    title: {},
    subtitle: {},
    description: {},
    row:{
        display:"flex",
        flexDirection:"column",
        width:"100%",
        [theme.breakpoints.down('md')]:{
            marginLeft:"22px",
            marginRight:"22px"
        }

    },
    rowContainer:{
        display:'flex',
            width:"95%",
        overflow:"auto",
        margin:'0 auto',
     
        gap:'50px',
        '&::-webkit-scrollbar':{
            // width:'8px',
            height:"11px",
            backgroundColor: '#F5F5F5'
        },
        '&::-webkit-scrollbar-track':{
            background:'rgb(131, 93, 57)',
            borderRadius:'8px',
            // '-webkit-box-shadow':'inset 0 0 6px' 
        },
        '&::-webkit-scrollbar-thumb':{
            background:'rgb(73, 39, 21)',
            border:'1px solid rgb(73, 39, 21)',
            borderRadius:'8px'

        }
    },
    borderimg2:{
        borderStyle: 'solid',
        borderWidth: '10px',
        borderImage: `url(${borderFrame}) 42 stretch`,
        borderImageWidth: '28px',
        background:`#ffd554`,
        backgroundClip:"padding-box",
        [theme.breakpoints.down('md')]:{
            borderImageWidth: '25px',
            padding:"50px",
        },
        [theme.breakpoints.down('sm')]:{ 
            padding:"10px",
          
        }
    },
    roadMapdescription:{
        fontSize:"18px !important",
        marginTop:0
    },
    roadMapGoalReached: {
        filter: 'grayscale(1)',
        textDecoration: 'line-through'
    }
}))
const Roadmap = () => {
    const classes = useStyles();
    return (
        <div className={classes.roadmapRoot}>
            <div className={`${classes.container} roadmapContainer`}>
                <div className={`${classes.containerBody}  ${classes.borderimg2}`}>
                    <h2 className="text-center">PROJECT <span>ROADMAP</span></h2>
                    <p  className={`text-center ml-8 mr-8 ${classes.roadMapdescription}`}>The time frame and items on the roadmap are for reference only and are subject to change</p>
                    <div className={`${classes.rowContainer}`}>
                        <div className={classes.row}>
                            <div className={classes.textGrid}>
                                <div className={`${classes.itemContainer} ${classes.roadMapGoalReached}`}>
                                    <div className={classes.pictureContainer}>
                                        <img className={classes.treasureChestOpen02} src={treasureChestOpen02} alt=""/>
                                    </div>
                                    <p className={classes.title}>Presale</p>
                                    <p className={classes.subtitle}>January 8th 3:00PM UTC</p >
                                    <p className={classes.description}>
                                        We sold Uncommon, Rare, and Ultra-Rare Crypto Pirates to the people in our white list.
                                    </p>
                                </div>
                                <div className={`${classes.itemContainer} ${classes.roadMapGoalReached}`}>
                                    <div className={classes.pictureContainer}>
                                        <img className={classes.itemImage} src={treasureChestOpen03} alt="" />
                                    </div>
                                    <p className={classes.title}>Regular Sale</p>
                                    <p className={classes.description}>
                                        We started the regular sale to everybody.
                                    </p>
                                </div>
                                <div className={`${classes.itemContainer} ${classes.roadMapGoalReached}`}>
                                    <div className={classes.pictureContainer}>
                                        <img className={classes.itemImage} src={GhostPirate} alt="" />
                                    </div>
                                    <p className={classes.title}>Ghosts Pirates Minting</p>
                                    <p className={classes.description}>
                                        We locked the number of Ghost Pirates and opened the minting to the people that are entitled to having one.
                                    </p>
                                </div>
                                <div className={classes.itemContainer}>
                                    <div className={classes.pictureContainer}>
                                        <img
                                            className={`${classes.itemImage} ${classes.arcade}`}
                                            src={arcade} alt=""
                                        />
                                    </div>
                                    <p
                                        className={classes.title}
                                        style={{fontSize: '1.26em', fontWeight: 'bold'}}
                                    >
                                            Videogame Development Announcement
                                    </p>
                                    <p className={classes.description}>
                                        Game development will start. We will announce the game play mechanics and details. We will give weekly updates and sneak peaks (as usual).
                                    </p>
                                </div>
                                <div className={classes.itemContainer}>
                                    <div className={classes.pictureContainer}>
                                        <img className={classes.itemImage} src={land} alt="" />
                                    </div>
                                    <p className={classes.title}>Videogame Land Sale</p>
                                    <p className={classes.subtitle}>&nbsp;</p>
                                    <p className={classes.description}>
                                        Videogame land sale for other game characters. Pirates will get a free ship as perk.
                                    </p>
                                </div>
                                <div className={classes.itemContainer}>
                                    <div className={classes.pictureContainer}>
                                        <img className={classes.itemImage} src={pirateFlag} alt="" />
                                    </div>
                                    <p
                                        className={classes.title}
                                        style={{fontSize: '1.26em', fontWeight: 'bold'}}
                                    >
                                        First Grand Treasure Hunt
                                    </p>
                                    <p className={classes.description}>
                                        50% of our Crypto Pirates owners will receive a prize.
                                    </p>
                                </div>
                                <div className={classes.itemContainer}>
                                    <div className={classes.pictureContainer}>
                                        <img className={classes.itemImage} src={pirateFlag} alt="" />
                                    </div>
                                    <p
                                        className={classes.title}
                                        style={{fontSize: '1.26em', fontWeight: 'bold'}}
                                    >
                                        Second Grand Treasure Hunt
                                    </p>
                                    <p className={classes.subtitle} style={{ margin: 0, padding: 0 }}>&nbsp;</p>
                                    <p className={classes.description}>
                                        Another 50% of our Crypto Pirates owners will receive a prize.
                                    </p>
                                </div>
                                <div className={classes.itemContainer}>
                                    <div className={classes.pictureContainer}>
                                        <a href="https://discord.gg/s3W2TVckkG" target="_blank">
                                            <Discord fill="#000000" className={classes.itemImage} style={{width: '9em'}} />
                                        </a>
                                    </div>
                                    <p
                                        className={classes.title}
                                        style={{fontSize: '1.26em', fontWeight: 'bold'}}
                                    >
                                        <a href="https://discord.gg/s3W2TVckkG" style={{ color: 'black', textDecoration: 'none'}} target="_blank">
                                            Check Our Discord Server
                                        </a>
                                    </p>
                                    <p className={classes.subtitle} style={{ margin: '0.6em 0' }}>&nbsp;</p>
                                    <p className={classes.description}>
                                        Check our Discord server for more information and an updated roadmap.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Roadmap

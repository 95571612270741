import React from 'react'
import { makeStyles } from '@mui/styles';
import lootBg from '../../../../Assets/LandingPage/lootBg.png';
import box4 from '../../../../Assets/LandingPage/box4.png';
import treasure1 from '../../../../Assets/LandingPage/treasure_chest_open_01.svg';
import treasure2 from '../../../../Assets/LandingPage/treasure_chest_open_02.svg';
import treasure3 from '../../../../Assets/LandingPage/treasure_chest_open_03.svg';
import chestClosed from '../../../../Assets/LandingPage/treasure_chest_closed.svg';
import borderFrame from '../../../../Assets/PersonalPage/borderFrame.png';
import box1_bg from '../../../../Assets/LandingPage/box1_bg.png';
import box2_bg from '../../../../Assets/LandingPage/box2_bg.png';
import box3_bg from '../../../../Assets/LandingPage/box3_bg.png';
import box4_bg from '../../../../Assets/LandingPage/box4_bg.png';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const videoSizes = {
    large: {
        width: 995,
        height: 536
    },
    medium: {
        width: 800,
        height: 469
    },
    small: {
        width: 600,
        height: 355
    },
    phone: {
        width: 350,
        height: 210
    }
};
const borderDifferenceWidth = 64;

const useStyles =makeStyles((theme:any) =>({
    trailerRoot:{
        position:"relative",
        // background:"blue",
        minHeight:"45em",
        [theme.breakpoints.down('md')]: {
            background:"#CEECFA",
            minHeight: '38em'
        },
        [theme.breakpoints.down('sm')]: {
            background:"#CEECFA",
            minHeight: '26em'
        },
        '&::before':{
            content:"''",
            backgroundImage:`url(${lootBg})`,
            backgroundSize:"cover",
            backgroundPosition:"center",
            backgroundRepeat:"no-repeat",
            [theme.breakpoints.down('md')]:{
                background:"#CEECFA"
            },
            position:"absolute",
            width:'100%',
            height:"100vh",
            top:'-5em',
            zIndex:-1

        }
    },
    mainContainer: {
        position:"relative",
        top:"-2em",
        maxWidth:"var(--max-width)",
        margin:"0 auto",
        // display:'flex',
        justifyContent:'center',
        flexDirection:"column",
        '& > h2':{
            margin: '0 0 0.4em 0',
            textAlign:"center",
            position:"relative",
            fontSize:"2.5em",
            '& span': {
                color: '#bc2819'
            }
        }
    },
    videoContainer:{
        borderStyle: 'solid',
        borderWidth: '10px',
        borderImage: `url(${borderFrame}) 42 stretch`,
        borderImageWidth: '23px',
        width: `${videoSizes['large'].width - borderDifferenceWidth}px`,
        height: `${videoSizes['large'].height}px`,
        background:`#ffd554`,
        backgroundClip:"padding-box",
        margin: '0 auto',
        minHeight:"20vh",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        padding:"1em",
        [theme.breakpoints.down('md')]: {
            borderImageWidth: '25px',
            width: `${videoSizes['medium'].width}px`,
            height: `${videoSizes['medium'].height}px`,
        },
        [theme.breakpoints.down(810)]: {
            width: `${videoSizes['small'].width}px`,
            height: `${videoSizes['small'].height}px`,
        },
        [theme.breakpoints.down(605)]: {
            width: `${videoSizes['phone'].width}px`,
            height: `${videoSizes['phone'].height}px`,
        }
    },
    videoWrapper:{
        background:`#ffd554`,
        backgroundClip:"padding-box",
        borderRadius:"50px"
    },
    video: {
        width: `${videoSizes['large'].width}px`,
        height: `${videoSizes['large'].height}px`,
    }
}))
const Trailer = () => {
    const classes =useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <div className={classes.trailerRoot}>
            <div className={`${classes.mainContainer}`}>
                <h2>THE <span>BEGINNING</span></h2>
                <div className={`${classes.videoContainer} ${classes.videoWrapper}`}>
                    <iframe className={classes.video} src="https://player.vimeo.com/video/639082684?h=5e629676f4" allow="autoplay; fullscreen; picture-in-picture"></iframe>
                </div>
            </div>
        </div>
    )
}

export default Trailer;

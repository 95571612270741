import React from 'react'
import { makeStyles } from '@mui/styles';
import woodFooter from '../../../../Assets/LandingPage/woodFooter.png';
import personal_button from '../../../../Assets/PersonalPage/personal_button.png';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
const useStyles = makeStyles((theme:any)=>({
    footerRoot:{
            position:"relative",
            height:"70px",
            zIndex:1000,
     
           '& p':{
            fontFamily:"'PT Sans', sans-serif !important",
            [theme.breakpoints.down('md')]:{
                padding:"10px" ,
                fontSize:"0.75rem",
                textAlign:"center"
            }
           }
    
    },
    woodenPlankDiv:{
        position: "absolute",
        width: "100%",
        height: "100%",
        zIndex: -1,
        top:0
    },
    personalAreaSpan:{
        background:'#bb2719',
        padding:"8px 15px",
        color:"white",
        fontSize:"0.625rem",
        width:"110px",
        display:"block",
        fontFamily:"'PT Sans', sans-serif !important",
        borderRadius:"10px"
    },
    footerContent:{
        maxWidth: 'var(--max-width)',
        margin: "0 auto",
        padding: "0.5em 1em",
        position: 'relative',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: "flex-end",
        [theme.breakpoints.down(500)]: {
            width: '400px',
            alignItems: 'center'
        }
    },
    personalPage: {
        justifyContent: 'space-between',
        height: 'auto'
    }
}))
const Footer = ({personal}:any) => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <div className={classes.footerRoot}>
              <div className={classes.woodenPlankDiv}>
            <img src={woodFooter} alt="" width="100%" height={matches ? '70px' :'75px'}/>
            </div>
            <div className={`${classes.footerContent}${personal ? ` ${classes.personalPage}` : ''}`}>
            {personal && <div className="ml-32 mt-16">
                {!matches && <img src={personal_button} alt="" width="200px"/>}
                {matches && <span className={classes.personalAreaSpan}>PERSONAL AREA </span>}
                </div>}
            <p style={{color: '#FFFFFF', fontWeight: 'bold'}}>
                &copy; 2022 Crypto Pirate, All Rights Reserved.
            </p>
        </div>
        </div>
    )
}

export default Footer

import React, { useContext, useEffect } from "react";
import TopContent from "./Components/TopContent/TopContent";
import What from "./Components/What/What";
import { makeStyles } from "@mui/styles";
// import TheLoot from "./Components/TheLoot/TheLoot";
import Roadmap from "./Components/Roadmap/Roadmap";
import Footer from "./Components/Footer/Footer";
import PageContext from "./../../Context/pageContext";
// import cloud3 from "../../Assets/LandingPage/cloud3.png";
// import { Parallax } from "react-scroll-parallax";
import Trailer from "./Components/Trailer/Trailer";

const useStyles = makeStyles({
  container: {
    width: "100%",
    overflow: "hidden",
  },
});
const LandingPage = () => {
  const classes = useStyles();
  const pageContext = useContext(PageContext);
  useEffect(() => {
    pageContext.setLocation(false);
  }, [pageContext]);
  return (
    <div className={`${classes.container} landingContainer`}>
      {/* <Navbar/> */}
      <TopContent />
    
      <What />
      <Trailer />
      <Roadmap />
      <Footer />
    </div>
  );
};

export default LandingPage;

import React, { useContext, useEffect, useRef, useState } from 'react'
import woodenPlank from '../../../../Assets/LandingPage/Wooden-toolbar.png';
import woddenLogo from '../../../../Assets/LandingPage/Wooden-logo.png';
import whiteLogo from '../../../../Assets/LandingPage/logo.svg';
import { ReactComponent as Discord } from '../../../../Assets/LandingPage/discord.svg';
import { ReactComponent as Twitter } from '../../../../Assets/LandingPage/twitter.svg';
import { ReactComponent as Opensea } from '../../../../Assets/LandingPage/opensea.svg';
import personalPage from '../../../../Assets/PersonalPage/personalPage.png';
import { makeStyles } from '@mui/styles';
import box2 from '../../../../Assets/LandingPage/navbarDropDownFrame.png';
import blackBox2 from '../../../../Assets/LandingPage/blackDevider.png'
import {
    Modal,
    useModal,
    ModalTransition,
  } from 'react-simple-hook-modal';
import 'react-simple-hook-modal/dist/styles.css';
import metaMask from '../../../../Assets/LandingPage/modalIcons/metaMask.png'
import mewWallet from '../../../../Assets/LandingPage/modalIcons/mewWallet.png'
import walletConnect from '../../../../Assets/LandingPage/modalIcons/walletConnect.png'
import walletLink from '../../../../Assets/LandingPage/modalIcons/walletLink.png';
import Devider from '../../../../Assets/LandingPage/modalIcons/Divider.png';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';
import borderFrame from '../../../../Assets/PersonalPage/borderFrame.png';
import PageContext from '../../../../Context/pageContext';
import {useHistory} from 'react-router-dom'

const useStyles = makeStyles((theme:any)=>({
    rootNavbar:{
        position:'fixed',
        width: '100%',
        // overflow:'hidden',
        height: 'auto',
        zIndex:100000,
    },
    blackToolbar: {},
    woodenPlankContainer:{
        position: 'absolute',
        width: '100%',
        height: '4.7em',
        '$blackToolbar &': {
            background: 'black'
        }
    },
    woodenPlank: {
        height: '4.7em',
        width: '100%'
    },
    navItemsContainer:{
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth:'var(--max-width)',
        height: '3.9em',
        padding:'0 2em',
        [theme.breakpoints.down(500)]:{
            padding:'0 1em'
        },
        margin:'0 auto',
        '&  $rightItemsListContainer, & $toolbarLogo': {
            display: 'flex',
            alignItems: 'center'
            // height: '70px',
            // [theme.breakpoints.down('lg')]:{
            // height: '55px',
            // }
        },
        '& $toolbarLogo': {
            justifyContent:'flex-start',
            marginLeft: '3em',
            marginTop: '0.5em',
            width: '5.9em',
            [theme.breakpoints.down(500)]:{
                marginLeft: '1em'
            }
        },
        '$blackToolbar & $toolbarLogo': {
            height: '2.5em',
            marginTop: '0.9em'
        },
       '& $rightItemsListContainer': {
            justifyContent: 'flex-end'
       },
       '$blackToolbar & $rightItemsListContainer': {
            marginTop: '0.4em'
        }
    },
    toolbarLogo: {},
    toolbarText: {
        color: 'white',
        fontSize: '1.45em',
        letterSpacing: '0.12em',
        [theme.breakpoints.down('md')]:{
            fontSize: '1.2em',
            paddingLeft: '1em',
            textAlign: 'right',
            marginTop: '0.6em'
        },
        [theme.breakpoints.down('sm')]:{
            fontSize: '0.85em',
            marginTop: '1em'
        },
        [theme.breakpoints.down(521)]:{
            fontSize: '0.7em'
        },
        [theme.breakpoints.down(350)]:{
            fontSize: '0.6em'
        },
        [theme.breakpoints.down(350)]:{
            fontSize: '0.5em'
        }
    },
    rightItemsListContainer: {},
    rightItemsList:{
        margin: 0,
        padding: 0,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        listStyle:'none',
        paddingRight: '20px',
        '& > li ':{
            padding: '0.32em 0.63em'
        },
        '& > li > a ':{
            display: 'flex',
            textDecoration: 'none'
        },
        '& > li > a > img':{
            width: '1.8em'
        },
        [theme.breakpoints.down(521)]:{
            paddingRight: 0,
            fontSize: '0.9em'
        }
    },
    personalText:{
        fontSize:'1.5rem',
        lineHeight:'18px',
        fontWeight:'bold',
        color:'white'
    },
    connectButton: {
        width: '139px',
        height: '30px',
        borderRadius: '17px',
        backgroundColor: '#bb2719',
        border: 'none',
        cursor: 'pointer',
        color: 'white',
        textTransform: 'uppercase',
        fontSize: '0.65rem',
        letterSpacing: '0.9px',
        transition: '0.2s ease-in-out',
        fontFamily: 'myFont',
        '&:hover':{
            background: '#660c04'
        }
    },
    connectDropDown:{
        position:'fixed',
        width:'200px',
        height:'200px',
        right:'51px',
        top:'55px',
        [theme.breakpoints.down('md')]:{
            right:'6px !important'
        },
        
        zIndex:1000,
        '& p:hover':{
            '& a':{
                // background:'black',
            color:'rgb(64,64,64) !important',
            // borderBottom:'1px solid grey'
            },
            color:'rgb(64,64,64) !important'
            
        },
        '& img':{
            position:'absolute',
            zIndex:1
        },
        '& p:nth-child(2)':{
            position:'relative',
            zIndex:1000,
            fontSize:'0.75rem',
            textAlign:'center',
            width:'75%',
            top:'80px',
            margin:'10px auto 10px auto',
            cursor:'pointer',
            padding:'0 20px',
            transition:'0.2s ease',
            '&:hover':{
                transform:'scale(1.09)'
            }
        },
        '& p:nth-child(3)':{
            position:'relative',
            zIndex:1000,
            top:'95px',
            fontSize:'0.75rem',
            textAlign:'center',
            width:'100%',
            cursor:'pointer',
            '&:hover':{
                transform:'scale(1.09)'
            }
        }
    },
    modalGrid:{
        display:'grid',
        gridTemplateColumns:'1fr 1fr',
        height:'auto',
        alignItems:'center',
        padding:'20px',
        background:`url(${Devider}) no-repeat center center/cover`,
        backgroundSize:'90% 90%',
        position:'absolute',
        left:0,
        right:0,
        top:0,
        bottom:0,
        [theme.breakpoints.down('sm')]:{
            marginTop:'20px'
        },
       
        '& div':{
            justifySelf:'center',
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            justtifyContent:'center',
            padding:'5px 10px',
        },
        '& span:nth-child(2)':{
            marginTop:'10px',
            fontFamily:'"PT Sans", sans-serif !important',
            lineHeight:'24px',
            fontSize:'1.125rem',
            fontWeight:'bold',
            [theme.breakpoints.down('sm')]:{
            fontSize:'0.75rem',

            }
        },
        '& span:nth-child(3)':{
            fontFamily:'"PT Sans", sans-serif !important',
            fontSize:'0.875rem',
            fontWeight:'normal',
            display:'block',
            textAlign:'center',
            [theme.breakpoints.down('sm')]:{
                fontSize:'0.75rem',
                }
        }
    },
    borderimg2:{
        borderStyle: 'solid',
        borderWidth: '10px',
        borderImage: `url(${borderFrame}) 42 stretch`,
        borderImageWidth: '15px',
        background:`#ffd554`,
        backgroundClip:'padding-box',
        [theme.breakpoints.down('md')]:{
            borderImageWidth: '25px',
            height:'480px',
            padding:'30px',
          
        },
        [theme.breakpoints.down('sm')]:{
            height:'480px',
            padding:'10px',
            width:'95%'
        },
    },
}));

interface NavbarProps {
    personal: boolean;
}

const enableWallet = false;

const Navbar = ({personal}: NavbarProps) => {
    const classes = useStyles();
    const { isModalOpen, openModal, closeModal } = useModal();
    const [activeDropDown,setActiveDropdown] = useState(false);
    const [isToolbarBlack,setIsToolbarBlack] = useState(false);
    const wrapperRef = useRef<any>();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    const [walletSelected,setWalletSelected] = useState(false);
    const pageContext = useContext(PageContext);
    const history = useHistory();

    useEffect(()=>{
        setActiveDropdown(false)
    },[pageContext])

    const getLandingContainer= (fixed:any)=> {
        let container= document.querySelector('.landingContainer');
        container?.setAttribute('style',`position:${fixed}`)
    }

    const handleCloseOutside= (e:any)=> {
        const {current:wrap} =wrapperRef;
        if(wrap && !wrap.contains(e.target)){
            setActiveDropdown(false)
        }
    }

    const listenToScroll= ()=> {
        if (window.scrollY > 500) {
            setIsToolbarBlack(true);
        } else {
            setIsToolbarBlack(false);
        }
    }

    const connectWalletHandler= ()=> {
        if(!walletSelected){
            openModal();
            getLandingContainer('fixed')
        }else{
            setActiveDropdown(true);
        }
    }

    const walletOptionClickHandler=()=>{
        setWalletSelected(true)
        closeModal();
        getLandingContainer('static');
    }

    const backdropHandler=()=>{
        closeModal();
        getLandingContainer('static');
    }

    const disconnectWalletHandler =()=>{
        setWalletSelected(false);
        history.replace('/')
    }

    useEffect(() => {
        window.addEventListener('mousedown',handleCloseOutside);
        return () => {
            window.removeEventListener('mousedown',handleCloseOutside)
        }
    })
    useEffect(() => {
        window.addEventListener('scroll',listenToScroll)
    },[])

    const rootClasses = `${classes.rootNavbar}${`${isToolbarBlack ? ` ${classes.blackToolbar}` : ''}`}`;
    return (
        <div className={rootClasses}>
            <div className={classes.woodenPlankContainer}>
                {!isToolbarBlack ?
                    <img
                        className={classes.woodenPlank}
                        src={woodenPlank}
                        alt=""
                    />
                    :''
                }
            </div>
            <div className={classes.navItemsContainer}>
                <img
                    className={classes.toolbarLogo}
                    src={isToolbarBlack ? whiteLogo : woddenLogo}
                    alt=""
                />
                { isToolbarBlack ?
                    <span className={classes.toolbarText}>Crypto Pirates</span>
                    : ''
                }
                {personal && <img style={{display:matches ? "none" : "initial"}} className={classes.personalText} src={personalPage} width={matches ? '100px':"250px"} alt=""/>}
                <div className={classes.rightItemsListContainer}>
                    <ul className={classes.rightItemsList}>
                        <li>
                            <a href="https://twitter.com/nftcryptopirate" target="_blank">
                                <Twitter fill="#FFFFFF" style={{width: '2em'}}/>
                            </a>
                        </li>
                        <li>
                            <a href="https://discord.gg/s3W2TVckkG" target="_blank">
                                <Discord fill="#FFFFFF" style={{width: '2em'}} />
                            </a>
                        </li>
                        {/* <li>
                            <a href="/#" target="_blank">
                                <Opensea fill="#FFFFFF" style={{width: '1.9em'}} />
                            </a>
                        </li> */}
                    </ul>
                        <div
                            onClick={connectWalletHandler}
                            ref={wrapperRef}
                        >
                            { enableWallet ?
                                <button className={classes.connectButton}>
                                    {walletSelected ? '0x123922...435' : 'Connect Wallet'}
                                </button>
                                : ''
                            }
                            {(walletSelected && activeDropDown) ?
                                <div style={{right:isToolbarBlack ? '41px' : '45px'}} className={`${classes.connectDropDown} `}>
                                    <img src={isToolbarBlack ? blackBox2: box2} alt="" width="100%" height="100%"/>
                                   <p style={{color: isToolbarBlack ? 'white':'black'}}>
                                       <NavLink style={{textDecoration:"none", color: isToolbarBlack ? 'white':'black'}} to={'/personal-page'}>
                                           Personal Area
                                        </NavLink>
                                    </p>
                                    <p  onClick={disconnectWalletHandler} style={{color:isToolbarBlack ? 'white':'black'}}>Disconnect Wallet</p>
                                </div>
                                : ''
                            }
                            </div>
                </div>
            </div>
            <Modal
                id="any-unique-identifier"
                isOpen={isModalOpen}
                transition={ModalTransition.TOP_DOWN}
                onBackdropClick={backdropHandler}
            >
                <div className={classes.modalGrid}>
                    <div className="cursor-pointer" onClick={walletOptionClickHandler}>
                        <img src={metaMask} alt=""  width="80px"/>
                        <span>MetaMask</span>
                        <span>Connect to your MetaMask Wallet</span>
                    </div>
                    <div className="cursor-pointer" onClick={walletOptionClickHandler}>
                    <img src={walletConnect} alt=""  width="80px"/>
                    <span>WalletConnect</span>
                        <span>Connect to your WalletConnect</span>
                    </div>
                    <div className="cursor-pointer" onClick={walletOptionClickHandler}>
                    <img src={mewWallet} alt=""  width="80px"/>
                    <span>MewWallet</span>
                        <span>Scan with MewWallet to connect</span>
                    </div>
                    <div className="cursor-pointer" onClick={walletOptionClickHandler}>
                    <img src={walletLink} alt=""  width="80px"/>
                    <span>Wallet Link</span>
                        <span>Scan with WalletLink to connect</span>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default Navbar

import React, { useEffect, useState } from 'react'
import crypto_pirates from '../../../../Assets/LandingPage/cryptoPirateHeader.svg';
import countDownToLaunch from '../../../../Assets/LandingPage/countDownToLaunch.png';
import headerImg from '../../../../Assets/LandingPage/headerBg.png';
import { makeStyles } from '@mui/styles';
import { useTimer } from 'react-timer-hook';
import wavesBack from '../../../../Assets/LandingPage/waves_back.svg';
import wavesFront from '../../../../Assets/LandingPage/waves_front.svg';
import wavesCenter from '../../../../Assets/LandingPage/waves_middle.svg';
import pirateShip from '../../../../Assets/LandingPage/pirate-ship.gif';
import mountains from '../../../../Assets/LandingPage/mountains.svg';
import cloud3 from '../../../../Assets/LandingPage/cloud3.png'
import ghostSale from '../../../../Assets/LandingPage/ghost-sale.png';
import pirates from '../../../../Assets/LandingPage/pirates.png';
import Minting from './Components/Minting';
import SoldOut from './Components/SoldOut';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const useStyles = makeStyles((theme:any)=>({
    '@keyframes wavesBack':{
        from: {
            marginLeft: '-10em'
        },
        to: {
            maginLeft: 0
        }
    },
    '@keyframes cloudLeft':{
        from: {
            left:'-20%'
          },
          to: {
            left: '130%'
          }

    },
    '@keyframes cloudRight':{
        from: {
            right: '-15%'
          },
          to: {
            right: '130%'
          }

    },
    topContentContainer:{
        position: 'relative',
        minHeight: '45em',
        marginTop:'9em',
        [theme.breakpoints.down('md')]:{
            fontSize:'0.8em',
        },
        '&::before':{
            content:'\'\'',
            background: `url(${headerImg}) no-repeat center center/cover`,
            position: 'absolute',
            top: '-187px',
            left: 0,
            right: 0,
            width: '100%',
            height: '223%',
            zIndex: -2
        },
       
    },
    logoContainer: {
        position: 'relative',
        width: '100%',
        textAlign: 'center'
    },
    logo:{
        width: '24em',
        zIndex: 1,
        [theme.breakpoints.down(500)]: {
            width: '18em'
        }
    },
    countdownContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection:'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        overflow: 'hidden',
        height: '22em',
        [theme.breakpoints.down(500)]: {
            justifyContent: 'flex-start',
            marginTop: '1em'
        }
    },
    timerContainer:{
        position:'relative',
        top:'-1.6em',
        display:'flex',
        alignItems:'center',
        [theme.breakpoints.down(500)]: {
            fontSize: '0.5em',
            marginTop: '7em'
        }
    },
    timerDigitContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0 0.4em'
    },
    timerDigitContainerDays: {
        marginRight: '0.6em',
    },
    timerDigit: {
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        width:'1.72em',
        height:'1.72em',
        background:'rgb(19, 19, 19)',
        color:'white',
        borderRadius:'0.3em',
        fontFamily:'sans-serif',
        lineHeight: 0,
        fontSize:'1.6rem'
    },
    timerFooter: {
        fontSize: '0.6em',
        fontWeight: 'bold'
    },
    timerDigitSeparator: {
        paddingBottom: '1em'
    },
    countDownBanner: {
        marginTop: '2em',
        width: '36em'
    },
    buyTokenContainer: {
        zIndex: 2,
        paddingBottom: '2em',
        [theme.breakpoints.down(500)]: {
            marginTop: '0.5em'
        }
    },
    buyToken:{
        marginTop:'8px',
        borderRadius: '10px',
        backgroundColor: '#bc281a',
        border:'none',
        color:'#fff',
        padding:'5px 15px',
        fontSize:'0.875rem',
        fontFamily: 'myFont',
        letterSpacing: '0.9px',
        cursor:'pointer',
        position:'relative',
        top:'-20px',
        zIndex:10
    },
    pirateShip:{
        position:'absolute',
        width: '20em',
        bottom: 0,
        [theme.breakpoints.down('md')]:{
            bottom: '1em'
        },
        [theme.breakpoints.down(680)]: {
            width: '15em',
            bottom: '1.7em'
        }
    },
    mountainsContainer:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        position:'absolute',
        width:'100%',
        bottom:'5.9em',
        right: '6em',
        zIndex: -1,
        [theme.breakpoints.down('md')]:{
            bottom: '8em'
        },
    },
    mountains: {
        height: '8em',
        width: '26em'
    },
    ghostSale: {
        position: 'absolute',
        width: '600px',
        marginTop: '2em',
        zIndex: -1,
        [theme.breakpoints.down('md')]: {
            width: '500px'
        },
        [theme.breakpoints.down(500)]: {
            width: '400px',
            marginTop: '-0.5em'
        }
    },
    pirates: {
        position: 'absolute',
        width: '600px',
        marginTop: '-8em',
        zIndex: -1,
        [theme.breakpoints.down('md')]: {
            width: '500px'
        },
        [theme.breakpoints.down(500)]: {
            width: '400px',
            marginTop: '-0.5em'
        }
    },
    wavesContainer: {
        position: 'absolute',
        bottom: 0,
        width: '110%',
        [theme.breakpoints.down('md')]:{
            bottom: '-3em'
        },
    },
    wave: {
        backgroundRepeat:'repeat-x',
        backgroundPosition:'center',
        height:'3.7rem',
        position:'relative'
    },
    wavesBack:{
        backgroundImage:`url(${wavesBack})`,
        top:'4.3em',
        left: '-2em',
        animation: '6s infinite alternate $wavesBack'
    },
    wavesCenter:{
        backgroundImage:`url(${wavesCenter})`,
        top:'2em',
        left:'-1.4em',
        animation: '5.8s infinite alternate $wavesBack'
    },
    wavesFront:{
        backgroundImage:`url(${wavesFront})`,
        top: 0,
        animation: '5s infinite alternate $wavesBack linear'
    },
    cloudContainer: {
        position: 'absolute',
        width: '130%'
    },
    cloud:{
        position:'absolute',
        width: '16em',
        zIndex: -2
    },
    cloud1:{
        top:'2.5em',
        left:0,
        animation: '50s infinite $cloudLeft linear'
    },
    cloud2:{
        top:'16em',
        right:0,
        animation: '30s infinite $cloudRight linear',
    }
}))
const TopContent = () => {
    const classes = useStyles();
    const [comingSoon] = useState(false);
    const [countdown] = useState(false);
    const [minting,setMinting] = useState(false);
    const [soldOut,setSoldOut] = useState(false);
    const {
        seconds,
        minutes,
        hours,
        days,
        start
      } = useTimer({ expiryTimestamp:getDate()})
      const theme = useTheme();
      const matches = useMediaQuery(theme.breakpoints.down('md'));
      const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));

      function getDate() {
        return new Date("2022-01-08T15:00:00.000+00:00");
      }

      function addTrailZero(number:number) {
        if (number<10){
            return `0${number}`;
        }
        return `${number}`;
      }

      useEffect(()=>{
        start()
        //eslint-disable-next-line
      },[]);

    return (
        <div className={classes.topContentContainer}>
            <div className={classes.cloudContainer}>
                <img className={`${classes.cloud1} ${classes.cloud}`} src={cloud3} alt="" />
                <img className={`${classes.cloud2} ${classes.cloud}`} src={cloud3} alt=""/>
            </div>
            <div className={classes.logoContainer}>
                <img className={classes.logo} src={crypto_pirates} />
            </div>
            <div className={classes.wavesContainer}>
                <div className={classes.mountainsContainer}>
                    <img className={classes.mountains} src={mountains}/>
                    {/* width={matches ? '80%':"29%"} */}
                </div>
                <div className={`${classes.wavesBack} ${classes.wave}`} />
                <div className={`${classes.wavesCenter} ${classes.wave}`} />
                <img className={classes.pirateShip} src={pirateShip} />
                <div className={`${classes.wavesFront} ${classes.wave}`}  />
            </div>
            {   soldOut ? (
                    <div  style={{position:"relative",zIndex:40,paddingBottom:'50px'}} className="text-center mt-32 d-flex flex-column align-items-center justify-content-center hidden">
                        <img src={crypto_pirates} style={{zIndex:1}} alt="" width={matches ? '150px':"280px"}/>
                        <SoldOut />
                    </div>
                )
                :''
            }
            {   minting ? (
                    <div style={{position:"relative",zIndex:40,paddingBottom:'50px'}} className="text-center mt-32 d-flex flex-column align-items-center justify-content-center hidden">
                        <img src={crypto_pirates} style={{zIndex:1}} alt=""  width={matches ? '150px':"280px"}/>
                        <Minting setSoldOut={setSoldOut}/>
                    </div>
                )
                : ''
            }
            {   countdown ? (
                    <div className={classes.countdownContainer}>
                        <img className={classes.countDownBanner} src={countDownToLaunch} />
                        <div className={classes.timerContainer}>
                            <div className={`${classes.timerDigitContainer} ${classes.timerDigitContainerDays}`}>
                                <span className={classes.timerDigit}>{addTrailZero(days)}</span>
                                <span className={`${classes.timerFooter} fontFamily`}>DAYS</span>
                            </div>
                            <div className={classes.timerDigitContainer}>
                                <span className={classes.timerDigit}>{addTrailZero(hours)}</span>
                                <span className={`${classes.timerFooter} fontFamily`}>HOURS</span>
                            </div>
                            <span className={classes.timerDigitSeparator}>:</span>
                            <div className={classes.timerDigitContainer}>
                                <span className={classes.timerDigit}>{addTrailZero(minutes)}</span>
                                <span className={`${classes.timerFooter} fontFamily`}>MINUTES</span>
                            </div>
                            <span className={classes.timerDigitSeparator}>:</span>
                            <div className={classes.timerDigitContainer}>
                                <span  className={classes.timerDigit}>{addTrailZero(seconds)}</span>
                                <span className={`${classes.timerFooter} fontFamily`}>SECONDS</span>
                            </div>
                        </div>
                        <div className="text-center">
                            <button className={classes.buyToken}> Buy Tokens</button>
                        </div>
                    </div>
                )
                : ''
            }
            {   comingSoon ? (
                    <div className={classes.countdownContainer}>
                        <img className={classes.ghostSale} src={ghostSale} alt="" />
                        {/* <div className={classes.timerContainer}>
                            <div className={`${classes.timerDigitContainer} ${classes.timerDigitContainerDays}`}>
                                <span className={classes.timerDigit}>{addTrailZero(days)}</span>
                                <span className={`${classes.timerFooter} fontFamily`}>DAYS</span>
                            </div>
                            <div className={classes.timerDigitContainer}>
                                <span className={classes.timerDigit}>{addTrailZero(hours)}</span>
                                <span className={`${classes.timerFooter} fontFamily`}>HOURS</span>
                            </div>
                            <span className={classes.timerDigitSeparator}>:</span>
                            <div className={classes.timerDigitContainer}>
                                <span className={classes.timerDigit}>{addTrailZero(minutes)}</span>
                                <span className={`${classes.timerFooter} fontFamily`}>MINUTES</span>
                            </div>
                            <span className={classes.timerDigitSeparator}>:</span>
                            <div className={classes.timerDigitContainer}>
                                <span  className={classes.timerDigit}>{addTrailZero(seconds)}</span>
                                <span className={`${classes.timerFooter} fontFamily`}>SECONDS</span>
                            </div>
                        </div> */}
                        {/* <div className={classes.timerContainer}>
                            <span style={{margin: '0.4em 0 0 0'}}>
                                PLEASE WAIT... PRESALE WILL START AS SOON AS GAS FEES WILL BE LOWER.
                                <p>CHECK OUT OUR DISCORD SERVER FOR UPDATES.</p>
                            </span>
                        </div> */}
                        <div className={classes.buyTokenContainer} onClick={()=>{window.open("https://mint.cryptopiratesnft.com/", "_blank")}}>
                            <button className={classes.buyToken} onClick={()=>{window.open("https://mint.cryptopiratesnft.com/", "_blank")}}>CLICK HERE TO MINT</button>
                        </div>
                    </div>
                
                )
                : (
                    <div className={classes.countdownContainer}>
                        <img className={classes.pirates} src={pirates} alt="" />
                    </div>
                )
            }
        </div>
    )
}

export default TopContent;
